import { PartecipanteItem } from "@/models/ricercaGaranzie/partecipanteItem";
import { TextValueItem } from "@/models/TextValueItem";
import api from "@/services/api";
import { Component } from "vue-property-decorator";
import gridComponentBase from "../components/grid/gridComponentBase.vue";
import { Filter } from "@/models/grid/filter";

const _ENDPONT = api.endpoint.MY_OVERINS.ELENCO_ESTRATTI_CONTO_NETWORK;

@Component({})
export default class EstrattiContoNetworkPage extends gridComponentBase {
	distributori: TextValueItem[] = [];

	get columns(): Array<any> {
		return [
			{ field: "itemID", title: "Num EC", width: 80, type: "numeric-int", filterType: "numeric-int", headerCell: "myHeaderCellTemplate", editHide: true, export: true },

			{ field: "anno", title: "Anno", headerCell: "myHeaderCellTemplate", type: "numeric-int", filterType: "numeric-int", filterable: true, export: true, width: 90, editHide: true },
			{ field: "mese", title: "Mese", headerCell: "myHeaderCellTemplate", type: "numeric-int", filterType: "numeric-int", filterable: true, export: true, width: 90, editHide: true },
			{
				field: "distributoreID",
				title: "Ragione Sociale",
				values: this.distributori,
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				filterable: true,
				sortBy: "distributore",
				itemsForFilter: this.distributori,
				filterType: "select"
			},
			{
				field: "distributore",
				title: "Ragione Sociale",
				hidden: true,
				export: true
			},
			{
				field: "numeroPratiche",
				title: "Pratiche",
				width: 100,
				headerCell: "myHeaderCellTemplate",
				type: "numeric-int",
				filterType: "numeric-int",
				format: "{0:n0}",
				editHide: true,
				export: true
			},
			{
				field: "daPagare",
				title: "Da Pagare",
				width: 100,
				headerCell: "myHeaderCellTemplate",
				type: "numeric",
				filterType: "numeric",
				format: "{0:n2}",
				editHide: true,
				export: true
			},
			{
				field: "totalePagato",
				title: "Pagato",
				width: 100,
				headerCell: "myHeaderCellTemplate",
				type: "numeric",
				filterType: "numeric",
				format: "{0:n2}",
				editHide: true,
				export: true
			},
			{
				field: "totaleSconto",
				title: "Sconto Ad.",
				width: 100,
				headerCell: "myHeaderCellTemplate",
				type: "numeric",
				filterType: "numeric",
				format: "{0:n2}",
				editHide: true,
				export: true
			},
			{
				field: "scontoEC",
				title: "Sconto EC",
				width: 100,
				headerCell: "myHeaderCellTemplate",
				type: "numeric",
				filterType: "numeric",
				format: "{0:n2}",
				export: true
			},
			{
				field: "saldo",
				title: "Saldo",
				width: 100,
				headerCell: "myHeaderCellTemplate",
				type: "numeric",
				filterType: "numeric",
				format: "{0:n2}",
				editHide: true,
				export: true
			},
			{
				field: "dataEC",
				title: "Creazione",
				width: 120,
				format: "{0:dd/MM/yyyy}",
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				filterable: true,
				export: true,
				type: "date",
				headerType: "daterange",
				editHide: true
			},
			{
				field: "dataInvio",
				title: "Invio",
				width: 120,
				format: "{0:dd/MM/yyyy}",
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				filterable: true,
				export: true,
				type: "date",
				headerType: "daterange",
				editHide: true
			},
			{
				field: "dataSollecito",
				title: "Sollecito",
				width: 120,
				format: "{0:dd/MM/yyyy}",
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				filterable: true,
				export: true,
				type: "date",
				headerType: "daterange",
				editHide: true
			},
			{
				field: "itemID",
				title: " ",
				width: 50,
				type: "renderfunction",
				renderFunction: this.renderCellEstrattoContoRep,
				editHide: true
				// template: function(dataItem) {
				// 	return "<a href='/CMSPages/Webins/WI_EC.aspx?idEC=" + dataItem.ItemID + "' target='_blank'>Apri</a>";
				// }
			}
		];
	}

	get columnsMobile(): Array<any> {
		return [
			{ field: "itemID", title: "Num EC", width: 80, type: "numeric-int", filterType: "numeric-int", headerCell: "myHeaderCellTemplate", editHide: true, export: true },

			{ field: "anno", title: "Anno", headerCell: "myHeaderCellTemplate", type: "numeric-int", filterType: "numeric-int", filterable: true, export: true, width: 90, editHide: true },
			{ field: "mese", title: "Mese", headerCell: "myHeaderCellTemplate", type: "numeric-int", filterType: "numeric-int", filterable: true, export: true, width: 90, editHide: true },
			{
				field: "distributoreID",
				title: "Ragione Sociale",
				values: this.distributori,
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				filterable: true,
				sortBy: "distributore",
				itemsForFilter: this.distributori,
				filterType: "select",
				headerType: "select"
			},
			{
				field: "distributore",
				title: "Ragione Sociale",
				hidden: true,
				export: true
			},
			{
				field: "numeroPratiche",
				title: "Pratiche",
				width: 100,
				headerCell: "myHeaderCellTemplate",
				type: "numeric-int",
				filterType: "numeric-int",
				format: "{0:n0}",
				editHide: true,
				export: true
			},
			{
				field: "daPagare",
				title: "Da Pagare",
				width: 100,
				headerCell: "myHeaderCellTemplate",
				type: "numeric",
				filterType: "numeric",
				format: "{0:n2}",
				editHide: true,
				export: true
			},
			{
				field: "totalePagato",
				title: "Pagato",
				width: 100,
				headerCell: "myHeaderCellTemplate",
				type: "numeric",
				filterType: "numeric",
				format: "{0:n2}",
				editHide: true,
				export: true
			},
			{
				field: "totaleSconto",
				title: "Sconto Ad.",
				width: 100,
				headerCell: "myHeaderCellTemplate",
				type: "numeric",
				filterType: "numeric",
				format: "{0:n2}",
				editHide: true,
				export: true
			},
			{
				field: "scontoEC",
				title: "Sconto EC",
				width: 100,
				headerCell: "myHeaderCellTemplate",
				type: "numeric",
				filterType: "numeric",
				format: "{0:n2}",
				export: true
			},
			{
				field: "saldo",
				title: "Saldo",
				width: 100,
				headerCell: "myHeaderCellTemplate",
				type: "numeric",
				filterType: "numeric",
				format: "{0:n2}",
				editHide: true,
				export: true
			},
			{
				field: "dataEC",
				title: "Creazione",
				width: 120,
				format: "{0:dd/MM/yyyy}",
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				filterable: true,
				export: true,
				type: "date",
				headerType: "daterange",
				editHide: true
			},
			{
				field: "dataInvio",
				title: "Invio",
				width: 120,
				format: "{0:dd/MM/yyyy}",
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				filterable: true,
				export: true,
				type: "date",
				headerType: "daterange",
				editHide: true
			},
			{
				field: "dataSollecito",
				title: "Sollecito",
				width: 120,
				format: "{0:dd/MM/yyyy}",
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				filterable: true,
				export: true,
				type: "date",
				headerType: "daterange",
				editHide: true
			},
			{
				field: "itemID",
				title: " ",
				width: 50,
				type: "renderfunction",
				renderFunction: this.renderCellEstrattoContoRep,
				editHide: true
				// template: function(dataItem) {
				// 	return "<a href='/CMSPages/Webins/WI_EC.aspx?idEC=" + dataItem.ItemID + "' target='_blank'>Apri</a>";
				// }
			}
		];
	}

	created() {
		var self = this;
		var p = [
			api.getDatiDropDown(api.endpoint.DROPDOWN.DISTRIBUTORI).then(res => (self.distributori = res)),
		];

		if (!(this.isAppMobile == false)) {
			this.pageable["buttonCount"] = 3;
			this.pageable["info"] = false;
			this.pageable["pageSizes"] = undefined;
		}

		Promise.all([p])
			.then(responses => {
				self.getData();
			})
			.catch(err => {
				console.log(err);
			});
	}

	renderCellEstrattoContoRep(data: string) {
		//var result = `<a href='/CMSPages/Webins/WI_EC.aspx?idEC=${data}' target='_blank' style='color: #5cb85c;'>Apri</a>`;
		var result = `<a href='${api.endpoint.REPORT}/reporting/pdf/estrattoContoDistributore/${data}' target='_blank' style='color: #5cb85c;'>Apri</a>`;

		return result;
	}


	exportExcel() {
		this.exportGridExcel(_ENDPONT);
	}

	getData() {
		this.getGridData(_ENDPONT);
	}

	showfilterDialog: boolean = false;

	onApplyFiltersMobile(filtersMobile: Filter) {
		this.filter = filtersMobile;
		this.onFilterMobileApply();
		this.onCloseDialogMessage();
	}
	onRemoveAllFilters() {
		this.clearSortAndFilter();
		//this.onCloseDialogMessage();
	}

	onCloseDialogMessage() {
		this.showfilterDialog = false;
	}

	onOpenFilterDialog() {
		this.showfilterDialog = true;
	}
}
